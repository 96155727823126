<template lang="pug">
    div(v-if="!loading" class="grid grid-cols-12 gap-4 my-6")
        // FreeTrialOption(
        //     v-if="subscriptions.length && !hasSubscription"
        //     class="col-span-12 md:col-span-3"
        //     :subscription="freeSubscription"
        //     :price="0"
        //     :option="subscriptionType"
        //     @set-subscription="updateSignupSessionSub"
        //    @set-subscription-option="setSubscriptionOption"
        // )
        div(
            v-for="subscription in subscriptions"
            class="col-span-12 md:col-span-4"
        )
            LiteOption(
                v-if="isLite(subscription)"
                :subscription="subscription"
                :price="subscription.price.formatted_value"
                :option="subscriptionType"
                @set-subscription="updateSignupSessionSub"
                @set-subscription-option="setSubscriptionOption"
            )
            PremiumOption(
                v-if="isPremium(subscription)"
                :subscription="subscription"
                :price="subscription.price.formatted_value"
                :option="subscriptionType"
                @set-subscription="updateSignupSessionSub"
                @set-subscription-option="setSubscriptionOption"
            )
        EnterpriseOption(
            v-if="subscriptions.length"
            class="col-span-12 md:col-span-4"
        )
        div(v-if="!subscriptions.length" class="col-span-12")
            Loader.h-16
    div(v-else class="w-50 m-auto")
        Loader

</template>

<script>
import LiteOption from '@/components/subscription/partials/LiteOption.vue'
import PremiumOption from '@/components/subscription/partials/PremiumOption.vue'
import EnterpriseOption from '@/components/subscription/partials/EnterpriseOption.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { SubscriptionType } from '@/common/enums/subscription.type'
import Loader from '@/assets/loader.svg'
// import FreeTrialOption from '@/components/subscription/partials/FreeTrialOption.vue'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: "SubscriptionOptions",
    components: { EnterpriseOption, PremiumOption, LiteOption, Loader },
    props: {
        previousLineItems: {
            type: Array,
            default: () => []
        },
        subscriptionId: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const signupSessionStore = useSignupSessionStore()
        const { updateSignupSessionSub, updateSignupSessionLineItems } = signupSessionStore
        const { signupSessionSub, signupSessionLineItems: roLineItems } = storeToRefs(signupSessionStore)
        const subscriptionIdComputed = computed(() => props.subscriptionId)
        const setDefaultSubscription = ref(false)
        const { setIsNewSub } = useActions(['setIsNewSub'])
        const { subscriptions, hardware: hardwareProducts } = useGetters(['subscriptions', 'hardware'])
        const loading = ref(true)
        const subscriptionType = computed(() => signupSessionSub.value?.option )
        const defaultSubscription = ref({})
        const liteOption = computed(() => subscriptions.value?.find((s) => isLite(s)))
        const hasSubscription = computed(() => !!subscriptionIdComputed.value)
        const freeSubscription = computed(() => {
            return {
                subscriptionId: subscriptionIdComputed.value,
                name: 'Free Trial',
                isTrial: true,
                price: {
                    id: liteOption.value?.price?.id,
                    formatted_value: 0,
                    recurring: { interval: 'free' }
                }
            }
        })

        const isLite = (subscription) =>  subscription?.metadata?.type.toLowerCase() === 'lite'
        const isPremium = (subscription) => subscription?.metadata?.type.toLowerCase() === 'premium'
        const isFreeTrial = (subscription) => subscription?.metadata?.type.toLowerCase() === 'freetrial'

        const setSubscriptionOption = (subscriptionOption) => {
            setIsNewSub(true)
            const temp = [...roLineItems.value]
            temp[0] = {...subscriptionOption}
            updateSignupSessionLineItems([...temp])
        }

        const updateDefaultSubscription = () => {
            defaultSubscription.value = {...(subscriptions.value.at(1) || {})}
            updateSignupSessionSub({
                price: defaultSubscription.value?.price?.formatted_value,
                priceId: defaultSubscription.value?.price?.id,
                option: SubscriptionType.PremiumPlan
            })
            const temp = [...roLineItems.value]
            temp[0] = {...defaultSubscription.value}
            updateSignupSessionLineItems([...temp])
        }
        const updateNewSubscription = (previousLineItems) => {
            const tempSubscription = previousLineItems.at(0)
            const linkedSubscription = subscriptions.value?.find((s) => s?.price?.id === tempSubscription.price)
            const subscriptionType = linkedSubscription?.metadata?.type.toLowerCase() === 'lite' ? SubscriptionType.LitePlan : SubscriptionType.PremiumPlan
            updateSignupSessionSub({
                price: linkedSubscription?.price?.formatted_value,
                priceId: tempSubscription.price,
                option: subscriptionType
            })
            const temp = [...roLineItems.value]
            temp[0] = {...linkedSubscription}
            updateSignupSessionLineItems([...temp])
        }

        watch(setDefaultSubscription, () => {
            if (subscriptions.value.length && setDefaultSubscription.value) updateDefaultSubscription()
        })

        watch(subscriptions, (newList) => {
            if (newList?.length && setDefaultSubscription.value) updateDefaultSubscription()
            loading.value = false
        }, { immediate: true })

        watch([() => props.previousLineItems, subscriptions], ([newValue, newSubscriptions]) => {
            if (newValue?.length) {
                updateNewSubscription(newValue)
                setDefaultSubscription.value = false
            } else {
                setDefaultSubscription.value = true
            }
        }, { immediate: true })

        return {
            loading,
            subscriptions,
            hardwareProducts,
            subscriptionType,
            freeSubscription,
            hasSubscription,
            isFreeTrial,
            isLite,
            isPremium,
            setSubscriptionOption,
            updateSignupSessionSub,
        }
    }
}
</script>
