<template lang="pug">
    div(:class="{'selectedPlan': isSelected}")
        div(v-if="isSelected" class="selectedMsg absolute flex items-center justify-center bg-primary rounded")
            fa-icon(icon="check" class="text-white text-xs") 
            div.text-xs.uppercase.font-semibold.ml-1 Selected
        div(class="p-4 text-center box-bg rounded")
            // img(src="@/assets/icons/icon-lite.png" alt="Lite" class="m-auto max-w-1/4 my-6")
            h4 Lite
            div.mt-1.mb-2.text-xs Single practice
            div.flex.items-center.justify-center.mt-4.text-white
                div.text-xl.text-white.font-semibold
                    | ${{price}}
                div.text-xs.ml-2 per month
            div.text-xs.uppercase.mb-4 {{ recurrenceText }}
            v-btn(
                class="w-full my-2"
                :class="{'text-white': !isSelected}"
                depressed
                :disabled="isSelected"
                :color="btnColor"
                raised
                large
                @click="setSubscription"
            )
                | {{ btnText }}
            v-expansion-panels(flat class="planFeatures")
                v-expansion-panel
                    v-expansion-panel-header
                        div.text-white.font-display.text-sm Features
                    v-expansion-panel-content
                        div.text-left.text-white.text-xs
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Patient satisfaction score
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Dedicated link for your practice survey
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Print your own survey with QR code
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Downloadable CSV for practice analysis
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div 1200 included surveys (per annum)
</template>

<script>
import { SubscriptionType } from '@/common/enums/subscription.type'
import { computed, watch } from '@vue/composition-api'
import { getRecurrenceText } from '@/components/subscription/partials/subscription.helpers'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'LiteOption',
    props: {
        subscription: {
            type: Object,
            required: true
        },
        option: {
            type: String,
            default: null
        },
        price: {
            type: Number,
            default: null
        }
    },
    emit: ['set-subscription', 'set-subscription-option'],
    setup(props, { emit }) {
        const { isFreeTrial, isNewSub } = useGetters(['isFreeTrial', 'isNewSub'])
        const isSelected = computed(() => props.option === SubscriptionType.LitePlan && (!isFreeTrial.value || isNewSub.value))
        const subscription = computed(() => props.subscription)
        const recurrenceText = computed(() => getRecurrenceText(props.subscription || {}))

        const setSubscription = () => {
            emit('set-subscription', {
                price: subscription.value.price?.formatted_price,
                priceId: subscription.value?.price?.id,
                option: SubscriptionType.LitePlan
            })
            emit('set-subscription-option', { ...subscription.value })
        }

        const btnText = computed(() => isSelected.value ? 'Selected' : 'Select Lite')
        const btnColor = computed(() => isSelected.value ? '' : 'primary')

        return { isSelected, btnText, btnColor, recurrenceText, setSubscription }
    }
}
</script>
